<template>
  <div class="base-list-page">
    <el-form :model="searchForm" :inline="true" class="bysearchForm">
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-form-item label="公司" prop="companyId">
            <el-select v-model="searchForm.companyId" filterable clearable>
              <el-option
                v-for="(item, index) in companyList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            v-if="isAuth('tc:companydepartment:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:companydepartment:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            >批量删除</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      style="width: 100%"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      />
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司"
      >
      </el-table-column>
      <table-tree-column
        prop="name"
        header-align="center"
        tree-key="id"
        label="部门名称"
      />
      <el-table-column
        header-align="center"
        align="center"
        width="220"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('tc:companydepartment:info')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('tc:companydepartment:update')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="isAuth('tc:companydepartment:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
  </div>
</template>

<script>
import AddOrUpdate from './department-add-or-update';
import { listMixin, normal } from '@/mixins';
import TableTreeColumn from '@/components/table-tree-column';
import { getCompanyList } from '@/utils/options.js';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      searchForm: {
        companyId: '',
      },
      dataList: [],
      companyList: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    TableTreeColumn,
    AddOrUpdate,
  },
  activated() {
    this.getCompanyList();
    this.getDataList(1);
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/tc/companydepartment/listTree',
        method: 'get',
        params: {
          companyId: this.searchForm.companyId,
        },
      }).then(({ data }) => {
        this.dataList = this.treeDataTranslate(
          data.departmentList,
          'id',
          'parentId',
          'childrens',
        );
      });
    },
    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对选中的数据进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/tc/companydepartment/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
